/**
 * @param {[
 *  id:0|1|2,
 * title:string
 * ]} productsNav id为当前次级导航栏索引，title为标题，组件会根据索引来显示导航栏的active
 */
export const productsNav = [
    {
        id: 0,
        title: "BIM全程咨询"
    },
    {
        id: 1,
        title: "工程项目建设管理平台"
    },
    {
        id: 2,
        title: "工程项目大数据建设"
    },
]

/**
 * @param {[
 *  id:0|1|2,
 * title:string
 * ]} projectNav id为当前次级导航栏索引，title为标题，组件会根据索引来显示导航栏的active
 */
export const projectNav = [
    {
        id: 0,
        title: "市政设施项目",
        href:'#a'
    },
    {
        id: 1,
        title: "建筑类项目",
        href:'#b'
    }
]
/**
 * @param {[
 *  id:0|1,
 * title:string
 * ]} functionNav id为当前次级导航栏索引，title为标题，组件会根据索引来显示导航栏的active
 */
export const functionNav = [
    {
        id: 0,
        title: "轻量化模型互动",
        href:'#a'
    },
    {
        id: 1,
        title: "720-VR全景图",
        href:'#b'
    }
]
/**
 * @param {[
 *  id:0|1|2|3,
 * title:string
 * ]} contactNav id为当前次级导航栏索引，title为标题，组件会根据索引来显示导航栏的active
 */
export const contactNav = [
    {
        id: 0,
        title: "公司简介",
        href:'#a'
    },
    {
        id: 1,
        title: "核心成员",
        href:'#b'
    },
    {
        id: 2,
        title: "新闻动态",
        href:'#c'
    },
    {
        id: 3,
        title: "联系我们",
        href:'#d'
    }
]
