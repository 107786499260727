// extracted by mini-css-extract-plugin
export var consultContainer = "product-module--consultContainer--S+ThM";
export var enterBox = "product-module--enterBox--ZHq2r";
export var enterContainer = "product-module--enterContainer--JvFMy";
export var main = "product-module--main--TARJy";
export var mainContainer = "product-module--mainContainer--vY9F3";
export var manageBox = "product-module--manageBox--laokf";
export var manageConslutContainer = "product-module--manageConslutContainer--mpfDS";
export var manageConsultBox = "product-module--manageConsultBox--bBVOm";
export var manageConsultTitle = "product-module--manageConsultTitle--wpKaD";
export var moduleContainer = "product-module--moduleContainer--fijw0";
export var moduleSub = "product-module--moduleSub--4igN0";
export var optionContainer = "product-module--optionContainer--kVIU4";
export var platBox = "product-module--platBox--n0OTd";
export var projectBox = "product-module--projectBox--o0zc2";
export var projectContainer = "product-module--projectContainer--HuaJB";
export var projectTrunk = "product-module--projectTrunk--dUEeX";
export var softCharaBox = "product-module--softCharaBox--bogb7";
export var spotContainer = "product-module--spotContainer--SwzP-";